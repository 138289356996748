export function getIsVisible(
  container: HTMLElement,
  childIndex: number,
  inaccuracy: number = 0.5,
): boolean {
  const slide = container.children[childIndex] as HTMLElement | null | undefined;

  if (!slide) return false;

  const viewportWidth: number = container.clientWidth;
  const slideWidth: number = slide.offsetWidth;
  const viewportCoordLeft: number = container.scrollLeft;
  const slideCoordLeft: number = slide.offsetLeft;
  const viewportCoordRight = viewportCoordLeft + viewportWidth;
  const slideCoordRight = slideCoordLeft + slideWidth;
  const allowOffset = slideWidth * inaccuracy;

  const isFitsLeft = slideCoordLeft >= viewportCoordLeft - allowOffset;
  const isFitsRight = slideCoordRight <= viewportCoordRight + allowOffset;

  return isFitsLeft && isFitsRight;
}
