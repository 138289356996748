import { useRouter } from "next/router";
import { BASE_URL } from "src/data/constants";


const useCanonicalHref = (): string => {
  const { asPath } = useRouter();
  const withGetParam = asPath.includes('/?');

  if(withGetParam) {
    return BASE_URL + asPath;
  }

  const [pathWithoutParams] = asPath.split('/?');

  return BASE_URL + pathWithoutParams;
};

export default useCanonicalHref;
